<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add New" }} Manual Order
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="order-form">
          <v-select
            label="Template"
            v-model="fields.template_id"
            :items="templates"
            item-value="id"
            item-text="title"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('template_id')"
            :error-messages="errors['template_id']"
            v-on:change="changeTemplate()"
          ></v-select>

          <v-text-field
            label="Title *"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>

          <v-textarea
            label="Description"
            v-model="fields.description"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>

          <v-text-field
            label="Price *"
            v-model="fields.price"
            type="number"
            step="0.01"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('price')"
            :error-messages="errors['price']"
          ></v-text-field>

          <v-select
            label="Type *"
            v-model="fields.type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>

          <v-text-field
            label="Customer Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-text-field
            label="Customer email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-text-field
            label="Customer Address *"
            v-model="fields.address"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address')"
            :error-messages="errors['address']"
          ></v-text-field>
          <v-text-field
            label="Town"
            v-model="fields.town"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('town')"
            :error-messages="errors['town']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('city')"
            :error-messages="errors['city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>

          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>

          <v-text-field
            label="Tracker Ref Number"
            v-model="fields.tracker_ref_number"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('tracker_ref_number')"
            :error-messages="errors['tracker_ref_number']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="order-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      fields: {
        order_type: null,
        price: null,
        title: null,
        town: null,
        city: null,
        description: null,
        postcode: null,
        address: null,
        name: null,
        email: null,
        phone: null,
        source: "Manual",
      },

      types: ["Small Domestic", "Contract", "Commercial/Large Domestic"],
      errors: {},
    };
  },

  computed: {
    templates() {
      return this.$store.state.graham.templates["templates"];
    },
  },

  methods: {
    changeTemplate: function () {
      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("graham/templates/populateOrderFromTemplate", payload)

        .then((res) => {
          this.fields.description = res.data.template.description;
          this.fields.price = res.data.template.price;
          this.fields.title = res.data.template.title;
          this.fields.type = res.data.template.type;
        })
        .catch(() => {});
    },
    open(order = null) {
      if (order !== null) {
        this.order = order;
        this.isEditing = true;
        this.fields.order_type = order.order_type;
        this.fields.price = order.price;
        this.fields.town = order.town;
        this.fields.city = order.city;
        this.fields.postcode = order.postcode;
        this.fields.address = order.address;
        this.fields.name = order.name;
        this.fields.email = order.email;
        this.fields.phone = order.phone;
        this.fields.source = order.source;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.orderId = this.order.id;
      }

      this.$store
        .dispatch("graham/orders/saveOrder", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.errors = {};
      this.customer = {};
      this.fields = {
        order_type: null,
        price: null,
        town: null,
        city: null,
        postcode: null,
        address: null,
        name: null,
        email: null,
        description: null,
        phone: null,
        source: "Manual",
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
