<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Orders</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Orders"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                v-on="on"
                class="mr-2"
                @click="$refs.orderDialog.open()"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Manual Order</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                v-on="on"
                :to="{ name: 'module-graham-orders-archived' }"
              >
                <v-icon small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>View Archived Orders</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="orders"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no orders"
      >
        <template v-slot:item.created_at="{ item }">
          {{ formatDateTime(item.created_at) }}
        </template>

        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'module-graham-orders-individual',
              params: { orderId: item.id },
            }"
            >GPC{{ item.id }} - {{ item.name }}</router-link
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            v-if="item.status === 'processing'"
            color="blue"
            text-color="white"
            >Processing</v-chip
          >
          <v-chip
            label
            v-else-if="item.status === 'pending-payment'"
            color="warning"
            >Pending Payment</v-chip
          >
          <v-chip label v-else-if="item.status === 'cancelled'"
            >Cancelled</v-chip
          >
          <v-chip label v-else-if="item.status === 'complete'" color="success"
            >Complete</v-chip
          >
          <v-chip
            label
            v-else-if="item.status === 'generated'"
            color="blue"
            text-color="white"
            >Generated</v-chip
          >
          <v-chip
            label
            v-else-if="item.status === 'sent-to-customer'"
            color="orange"
            text-color="white"
            >Sent To Customer</v-chip
          >
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip
            label
            v-if="item.order_type === 'Small Domestic'"
            color="blue"
            text-color="white"
            >SD</v-chip
          >
          <v-chip
            label
            v-else-if="item.order_type === 'Contract'"
            color="warning"
            >C</v-chip
          >
          <v-chip
            label
            color="pink"
            text-color="white"
            v-else-if="item.order_type === 'Commercial/Large Domestic'"
            >CLD</v-chip
          >
        </template>

        <template v-slot:item.total="{ item }"> £{{ item.total }} </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            class="mr-2"
            :to="{
              name: 'module-graham-orders-individual',
              params: { orderId: item.id },
            }"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <order-dialog ref="orderDialog" />

    <v-dialog v-model="deleteOrder.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Order</v-card-title>
        <v-card-text
          >Are you sure you wish to delete GPC{{ deleteOrder.order.id }} -
          {{ deleteOrder.order.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed>No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteOrder.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderDialog from "./components/OrderDialog.vue";

export default {
  components: {
    OrderDialog,
  },
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Orders",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Order", value: "id" },
        { text: "Source", value: "source" },
        { text: "Date Placed", value: "created_at" },
        { text: "Status", value: "status" },
        { text: "Total", value: "total" },
        { text: "Title", value: "pest_name", width: "20%" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteOrder: {
        dialog: false,
        order: {},
        loading: false,
      },
    };
  },

  computed: {
    orders() {
      let orders = this.$store.state.graham.orders["orders"];

      if (this.searchTerm !== "") {
        orders = orders.filter((p) => {
          const name = p.name.toLowerCase();
          const email = p.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return orders;
    },
  },

  methods: {
    openDelete(order) {
      this.deleteOrder.order = order;
      this.deleteOrder.dialog = true;
    },

    resetDelete() {
      this.deleteOrder.dialog = false;
      this.deleteOrder.order = {};
      this.deleteOrder.loading = false;
    },

    saveDelete() {
      this.deleteOrder.loading = true;

      this.$store
        .dispatch("graham/orders/deleteOrder", {
          appId: this.$route.params.id,
          orderId: this.deleteOrder.order.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteSolicitor.loading = false;
        });
    },
  },
};
</script>
